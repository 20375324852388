
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import Multiselect from "vue-multiselect";


export default {
  page: {
    title: "Restaurant Menu Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      
      tableData: [],

      title: "Categories ",
      items: [
        {
          text: "View",
        },
        {
          text: "Categories",
          active: true,
        },
      ],
      restaurants:[],
      branches:[],
      restaurantID:"",
      branchID:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
     
        {
          key: "categoryName",
          
          sortable: true,
          //image: true,
        },
        {
          key: "created",
          
          sortable: true,
          //image: true,
        },

        
        
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getCategoryList();
    this.getRestaurantList();
    
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getCategoryList(){
         this.axios
        .post(this.$loggedRole+"/getCategoryList",
         {'restID':0,'empTypeID':this.$storageData.profile.empTypeID})
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.tableData = response.data.data;
           //console.log((response));
          
        });
    },
    getCategories(){
      this.getCategoryList();
    },

  getRestaurantList(){
    this.axios.post(this.$loggedRole+"/getRestaurantList",
    {'restID':this.$storageData.profile.restID,'restBranchID':this.$storageData.profile.restBranchID,'empTypeID':this.$storageData.profile.empTypeID})
    .then((result)=>{
      this.restaurants = result.data.data;
        
  });
},


  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <div class=" col-md-4">
           <label> Choose Restaurant Brand </label>
           <multiselect v-model="restaurantID" :options="restaurants" class="mb-3" track-by="restID" label="name" @input="getCategories();"></multiselect>
    
    </div> -->
   
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
                <div class="row mt-4">
               <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div role="group" class="btn-group">
                      <button type="button" class="btn btn-themeBrown" v-if="this.$storageData.login_type == 1">Excel</button>
                       <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                    </div>
               </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
                 fixed-header
              >
              
               <!-- <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                </template> -->
                
                <template slot="image" slot-scope="data">
                   <img  v-bind:src="data.item.name" />  
                </template>

              </b-table>
                
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

